import {
  FLUSH_STORAGE,
  SHOW_LOADER,
  HIDE_LOADER,
  SET_JOURNEY_PROPS,
  SET_OPEN_JOURNEY_PROPS,
  GET_JOURNEY_BEGIN,
  GET_JOURNEY_SUCCESS,
  GET_JOURNEY_FAILURE,
  GET_OFFER_BEGIN,
  GET_OFFER_SUCCESS,
  GET_OFFER_FAILURE,
  GET_CONFIGS_BEGIN,
  GET_CONFIGS_SUCCESS,
  GET_CONFIGS_FAILURE,
  DROP_USER_BEGIN,
  DROP_USER_SUCCESS,
  DROP_USER_FAILURE,
  UPDATE_JOURNEY_DATA,
  UPDATE_STORE,
  SAVE_JOURNEY_BEGIN,
  SAVE_JOURNEY_SUCCESS,
  SAVE_JOURNEY_FAILURE,
  VALIDATE_AGCODE_BEGIN,
  VALIDATE_AGCODE_SUCCESS,
  VALIDATE_AGCODE_FAILURE,
  SUBMIT_APPLICATION_BEGIN,
  SUBMIT_APPLICATION_SUCCESS,
  SUBMIT_APPLICATION_FAILURE,
  UPLOAD_DOCUMENT_BEGIN,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  OPMK_ELIGIBILE_BEGIN,
  OPMK_ELIGIBILE_SUCCESS,
  OPMK_ELIGIBILE_FAILURE,
  SUIT_MATRX_OFFER_BEGIN,
  SUIT_MATRX_OFFER_SUCCESS,
  SUIT_MATRX_OFFER_FAILURE,
  VALIDATE_PINCODE_BEGIN,
  VALIDATE_PINCODE_SUCCESS,
  VALIDATE_PINCODE_FAILURE,
  START_OPEN_JOURNEY_BEGIN,
  START_OPEN_JOURNEY_SUCCESS,
  START_OPEN_JOURNEY_FAILURE
} from "../actions/_types";
import {
  dataMapper,
  shrinkNoWObj,
  blockMapper,
  mapPartialObject
} from "../utils";
let initialState = {
  loading: false,
  error: false,
  journey: {},
  source: null,
  product: null,
  custId: null,
  openMarket: {},
  agentDetails: {
    agentCode: null,
    agCodeValidated: false,
    preValidated: false
  },
  campaign: null
};

export default function applicationReducer(state = initialState, action) {
  var { type, payload } = action;

  switch (type) {
    case FLUSH_STORAGE:
      return { ...initialState };
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case SET_JOURNEY_PROPS:
      var { custId, source, product , type} = payload;
      return {
        ...state,
        source: source.toUpperCase() || null,
        product: product.toUpperCase() || null,
        custId: custId,
        type : type, 
        loading: false,
        error: false
      };
    case SET_OPEN_JOURNEY_PROPS:
      return {
        ...state,
        loading: false,
        error: false,
        openMarket: payload,
        journey: {}
      };
    case "SET_CAMPAIGN_PROPS":
      return { ...state, campaign: { ...payload } };
    case GET_JOURNEY_BEGIN:
      return {
        ...state,
        loading: true,
        error: false,
        journey: {},
        openMarket: {}
      };
    case GET_JOURNEY_SUCCESS:
      let _personalDetails = { ...payload?.personalDetails };
      if (payload?.personalDetails?.addressMissing) {
        _personalDetails["pincode"] = "";
        _personalDetails["address"] = "";
        _personalDetails["areaOrLandmark"] = "";
        _personalDetails["city"] = "";
        _personalDetails["state"] = "";
        _personalDetails["country"] = "India";
      }
      return {
        ...state,
        loading: false,
        error: false,
        journey: { ...payload, personalDetails: { ..._personalDetails } }
      };
    case GET_JOURNEY_FAILURE:
      return { ...state, loading: false };
    case GET_OFFER_BEGIN:
      return { ...state, loading: true };
    case GET_OFFER_SUCCESS:
      var { journey } = state;

      journey.planDetails = Object.assign(
        {},
        dataMapper(state.journey.planDetails, payload)
      );
      return { ...state, loading: false, journey };
    case GET_OFFER_FAILURE:
      return { ...state, loading: false };
    case GET_CONFIGS_BEGIN:
      var { journey } = state;
      delete journey.pasaConfigs;
      return { ...state, loading: true, journey };
    case GET_CONFIGS_SUCCESS:
      var {
        agentCode,
        industryTypeDesignationDropMap,
        industryTypeDesignationMap,
        paymentGateway,
        requiredPersonalDetails
      } = payload;
      var workNatureMap = shrinkNoWObj(
        industryTypeDesignationMap,
        industryTypeDesignationDropMap
      );
      var { journey } = state;
      journey["pasaConfigs"] = {
        agentCode,
        paymentGateway,
        requiredPersonalDetails,
        workNatureMap
      };
      return {
        ...state,
        loading: false,
        error: false,
        journey
      };
    case GET_CONFIGS_FAILURE:
      var { journey } = state;
      delete journey.pasaConfigs;
      return { ...state, loading: false, journey };
    case DROP_USER_BEGIN:
      return { ...state, loading: true };
    case DROP_USER_SUCCESS:
      return { ...state, loading: false };
    case DROP_USER_FAILURE:
      return { ...state, loading: false };
    case UPDATE_JOURNEY_DATA:
      var { journey } = state;
      if (
        !journey?.planDetails?.previousSA &&
        !payload?.["planDetails"]?.["previousSA"]
      ) {
        delete journey?.["planDetails"]?.["previousSA"];
        delete payload?.["planDetails"]?.["previousSA"];
      }
      let mappedObj = blockMapper(journey, payload);
      return { ...state, loading: false, journey: mappedObj };
    case SAVE_JOURNEY_BEGIN:
      return { ...state, loading: true };
      case UPDATE_STORE:
        return { ...payload?.pasaStore };
    case SAVE_JOURNEY_SUCCESS:
      var updatedJourney = Object.assign({}, state.journey);
      if (action.stage === "plan") {
        updatedJourney = mapPartialObject(
          state.journey,
          payload.planDetails,
          "planDetails"
        );

        updatedJourney.currentPage = 1;
      } else if (action.stage === "personal") {
        updatedJourney = mapPartialObject(
          state.journey,
          payload.personalDetails,
          "personalDetails"
        );
        updatedJourney.currentPage = 2;
      } else if (action.stage === "nominee") {
        updatedJourney = mapPartialObject(
          state.journey,
          payload.nomineeDetails,
          "nomineeDetails"
        );
        updatedJourney.currentPage = 3;
      } else {
      }
      return {
        ...state,
        loading: false,
        error: false,
        journey: updatedJourney
      };
    case SAVE_JOURNEY_FAILURE:
      return { ...state, loading: false };
    case VALIDATE_AGCODE_BEGIN:
      return {
        ...state,
        loading: true,
        error: false,
        agentDetails: {
          agentCode: null,
          agCodeValidated: false,
          preValidated: false
        }
      };
    case VALIDATE_AGCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        agentDetails: {
          agentCode: payload.agentCode,
          agCodeValidated: true,
          preValidated: payload.preValidated || false,
          agentName: payload.agentName || null
        }
      };
    case VALIDATE_AGCODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        agentDetails: {
          agentCode: null,
          agCodeValidated: false,
          preValidated: false
        }
      };
    case SUBMIT_APPLICATION_BEGIN:
      return { ...state, loading: true };
    case SUBMIT_APPLICATION_SUCCESS:
      var { journey } = state;
      journey["appSubResponse"] = payload;
      journey["status"] = "APP_SUBMITTED";
      return { ...state, loading: false, journey };
    case SUBMIT_APPLICATION_FAILURE:
      return { ...state, loading: false };
    case UPLOAD_DOCUMENT_BEGIN:
      return { ...state, loading: true };
    case UPLOAD_DOCUMENT_SUCCESS:
      var { journey } = state;
      journey.showDocumentUploadScreen = false;
      return { ...state, loading: false, journey };
    case UPLOAD_DOCUMENT_FAILURE:
      return { ...state, loading: false };
    case "UPLOAD_ADDRESS_DOCUMENT_BEGIN":
      return { ...state, loading: true };
    case "UPLOAD_ADDRESS_DOCUMENT_SUCCESS":
      return { ...state, loading: false };
    case "UPLOAD_ADDRESS_DOCUMENT_FAILURE":
      return { ...state, loading: false };
    case OPMK_ELIGIBILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: false,
        journey: {},
        source: null,
        product: null,
        custId: null
      };
    case OPMK_ELIGIBILE_SUCCESS:
      var { userId, offers } = payload;
      var { userData, source: src } = action;
      var { openMarket } = state;
      openMarket["userId"] = userId;
      openMarket["offers"] = offers;
      openMarket["userData"] = userData;
      state.source = src;
      return {
        ...state,
        loading: false,
        error: false,
        openMarket: openMarket
      };
    case OPMK_ELIGIBILE_FAILURE:
      return { ...state, loading: false };
    case SUIT_MATRX_OFFER_BEGIN:
      return { ...state, loading: true };
    case SUIT_MATRX_OFFER_SUCCESS:
      return { ...state, loading: false };
    case SUIT_MATRX_OFFER_FAILURE:
      return { ...state, loading: false };
    case VALIDATE_PINCODE_BEGIN:
      return { ...state, loading: true };
    case VALIDATE_PINCODE_SUCCESS:
      return { ...state, loading: false };
    case VALIDATE_PINCODE_FAILURE:
      return { ...state, loading: false };
    case "OTP_GENERATE_BEGIN":
      return { ...state, loading: true };
    case "OTP_GENERATE_SUCCESS":
      return { ...state, loading: false };
    case "OTP_GENERATE_FAILURE":
      return { ...state, loading: false };
    case "OTP_VALIDATE_BEGIN":
      return { ...state, loading: true };
    case "OTP_VALIDATE_SUCCESS":
      return { ...state, loading: false };
    case "OTP_VALIDATE_FAILURE":
      return { ...state, loading: false };
    case "GET_JOURNEY_OTP_BEGIN":
      return { ...state, loading: true };
    case "GET_JOURNEY_OTP_SUCCESS":
      return { ...state, loading: false };
    case "GET_JOURNEY_OTP_FAILURE":
      return { ...state, loading: false };
    case "VALIDATE_JOURNEY_OTP_BEGIN":
      return { ...state, loading: true };
    case "VALIDATE_JOURNEY_OTP_SUCCESS":
      return { ...state, loading: false };
    case "VALIDATE_JOURNEY_OTP_FAILURE":
      return { ...state, loading: false };
    case START_OPEN_JOURNEY_BEGIN:
      return { ...state, loading: true };
    case START_OPEN_JOURNEY_SUCCESS:
      return { ...state, loading: false };
    case START_OPEN_JOURNEY_FAILURE:
      return { ...state, loading: false };
    default:
      return { ...state };
  }
}
