const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const OPEN_PASA_API_URL = process.env.REACT_APP_OPEN_PASA_API_URL;
const LOCALITY_API_URL = process.env.REACT_APP_LOCALITY_API_URL;

export const PASA_API = {
  DROP_USER: (custId) =>
    [BASE_API_URL, "v1", "journey", `drop?id=${custId}`].join("/"),
  TRACK_USER: (custId) =>
    [BASE_API_URL, "v1", "journey", `track?id=${custId}`].join("/"),
  GET_OFFER: (custId) =>
    [BASE_API_URL, "v1", "offer", `?id=${custId}`].join("/"),
  GET_CONFIGS: (custId) =>
    [BASE_API_URL, "v1", "config", `?id=${custId}`].join("/"),
  AGENT_CODE: (custId) => [BASE_API_URL, "v1", `agent?id=${custId}`].join("/"),
  AGENT_CODE_WITHOUT_ID: () => [BASE_API_URL, "v1", `agent/validate`].join("/"),
  JOURNEY_PROGRESS: (stage, custId) =>
    [BASE_API_URL, "v1", "journey", stage, `?id=${custId}`].join("/"),
  JOURNEY_SUBMIT: (custId) =>
    [BASE_API_URL, "v1", "journey", "submit", `?id=${custId}`].join("/"),
  VALIDATE_PINCODE: () => [BASE_API_URL, "v1", "journey", `pincode`].join("/"),
  ILLUSTRATION: (custId) =>
    [BASE_API_URL, "v1", `illustration?id=${custId}`].join("/"),
  OTP_JOURNEY_FETCH: [OPEN_PASA_API_URL, "v1", `offer`, `sendOtp`].join("/"),
  OTP_JOURNEY_VALIDATE: [OPEN_PASA_API_URL, "v1", `offer`, `validateOtp`].join(
    "/"
  ),
  FILE_UPLOAD: (custId) =>
    [BASE_API_URL, "v1", `document/uploadAddrProof?id=${custId}`].join("/"),
    SESSION_START: () => [BASE_API_URL, "v1", "journey", 'session-start'].join("/"),
    SESSION_END: () => [BASE_API_URL, "v1", "journey", 'session-end'].join("/"),
    POST_PLANDETAILS_SUBMIT :  () => [BASE_API_URL, "v1", "journey", 'basic-details'].join("/"),
};

export const OPEN_PASA_API = {
  GET_OFFERS: [OPEN_PASA_API_URL, "v1", `open-market`, `offer`].join("/"),
  START_JOURNEY: [OPEN_PASA_API_URL, "v1", `open-market`, `journey`].join("/"),
  FILE_UPLOAD: (custId) =>
    [OPEN_PASA_API_URL, "v1", `document?id=${custId}`].join("/"),
  SUITABILITY_MATRIX: [
    OPEN_PASA_API_URL,
    "v1",
    `open-market`,
    `questionnaire`
  ].join("/"),
  HBANK_OTP_GENERATE: [
    OPEN_PASA_API_URL,
    "v1",
    `open-market`,
    `generate-otp`
  ].join("/"),
  HBANK_OTP_VALIDATE: [
    OPEN_PASA_API_URL,
    "v1",
    `open-market`,
    `validate-otp`
  ].join("/")
};
