import React from "react";
export default class DateUtils {
  constructor(date) {
    this.date = new Date(date || "");
  }
  getAge = () => {
    var ageDifMs = Date.now() - this.date.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
}
