import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  withRouter
} from "react-router-dom";
import ScrollToTop from "./scrollToTop";
import LoaderWrapper from "./loaderWrapper";
import "../assets/styles/common.scss";
import Loader from "../views/common/loader";
import { postSessionStart } from "../utils";
import { useSelector } from "react-redux";
import axios from "axios";
const ErrorPage = React.lazy(() => import("../views/common/error"));
const DRCErrorPage = React.lazy(() => import("../views/common/drcError"));
const DropPage = React.lazy(() => import("../views/common/drop"));
const OpenMarketForm = React.lazy(() =>
  import("../views/journey/openMarket/form")
);
const OffersPage = React.lazy(() =>
  import("../views/journey/openMarket/offers")
);
const WelcomePage = React.lazy(() => import("../views/journey/welcome"));
const BasicDetails = React.lazy(() => import("../views/journey/basicDetails"));
const NrDetails = React.lazy(() => import("../views/journey/nrDetails"));
const PlanDetailsPage = React.lazy(() =>
  import("../views/journey/planDetails")
);
const DeclarationPage = React.lazy(() =>
  import("../views/journey/declarations")
);
const NomineePage = React.lazy(() => import("../views/journey/nomineeDetails"));
const PaymentPage = React.lazy(() => import("../views/journey/payment"));
const PaymentSuccessPage = React.lazy(() =>
  import("../views/journey/payment/success")
);
const PaymentFailurePage = React.lazy(() =>
  import("../views/journey/payment/failure")
);
const SummaryPage = React.lazy(() => import("../views/journey/summary"));
const CustAuthPage = React.lazy(() => import("../views/journey/custAuth"));
const ServerDownPage = React.lazy(() => import("../views/common/serverDown"));

function WaitingComponent(Component) {
  return (props) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
}

function AppRouter({ ...props }) {
  return (
    <LoaderWrapper>
      <div className="router-layout">
        <div className="container">
          <Router>
            <ScrollToTop>
              <Switch>
                <Route
                  path="/service-down"
                  component={WaitingComponent(ServerDownPage)}
                />
                <Route
                  path="/error/:errorType"
                  component={WaitingComponent(ErrorPage)}
                />
                <Route path="/error" component={WaitingComponent(ErrorPage)} />
                <Route
                  path="/drc/error"
                  component={WaitingComponent(DRCErrorPage)}
                />
                <Route
                  path="/drop/:drop_source"
                  component={WaitingComponent(DropPage)}
                />
                <Route path="/drop" component={WaitingComponent(DropPage)} />
                <Route
                  exact
                  path="/payment/failure"
                  component={WaitingComponent(PaymentFailurePage)}
                />
                <Route
                  exact
                  path="/payment/success"
                  component={WaitingComponent(PaymentSuccessPage)}
                />
                <Route
                  exact
                  path="/payment/:product/:partner/:id"
                  component={WaitingComponent(PaymentPage)}
                />
                <Route
                  exact
                  path="/application/summary/:product/:partner/:id/:appno"
                  component={WaitingComponent(SummaryPage)}
                />
                <Route
                  exact
                  path="/cust/auth/:partner/:product"
                  component={WaitingComponent(CustAuthPage)}
                />
                <Route
                  exact
                  path="/nominee/:product/:partner/:id"
                  component={WaitingComponent(NomineePage)}
                />
                <Route
                  exact
                  path="/declarations/:product/:partner/:id"
                  component={WaitingComponent(DeclarationPage)}
                />
                <Route
                  exact
                  path="/quote/:product/:partner/:id"
                  component={WaitingComponent(PlanDetailsPage)}
                />
                 <Route
                  exact
                  path="/quote/:product/:partner/:id/:PP2S"
                  component={WaitingComponent(PlanDetailsPage)}
                />
                <Route
                  exact
                  path="/basic-details/:product/:partner/:id"
                  component={WaitingComponent(BasicDetails)}
                />
                <Route
                  exact
                  path="/basic-details/nr/:product/:partner/:id"
                  component={WaitingComponent(NrDetails)}
                />
                <Route
                  path="/open-market/offers/:partner/:channel/:id"
                  component={WaitingComponent(OffersPage)}
                />
                <Route
                  path="/open-market/form/:partner/:channel"
                  component={WaitingComponent(OpenMarketForm)}
                />
                <Route
                  path="/"
                  component={WaitingComponent(WelcomePage)}
                  name="landing"
                />
                <Route
                  path="*"
                  component={WaitingComponent(WelcomePage)}
                  name="landing"
                />
              </Switch>
            </ScrollToTop>
          </Router>
        </div>
      </div>
      <SessionStart />
    </LoaderWrapper>
  );
}

const SessionStart = (second) => {
  const StoreData = useSelector((state) => state);
  const {
    pasaStore: { journey, source, custId, product }
  } = StoreData;

  if (product && custId && source && ["HBANK", "HBANKOM"].includes(source?.toUpperCase())) {
    axios.defaults.headers.common["x-server-api-key"] =
      process.env[
      `REACT_APP_API_KEY_${product?.toUpperCase()}_${source?.toUpperCase()}`
      ];
  }

  useEffect(() => {
    if (product && custId && source && ["HBANK", "HBANKOM"].includes(source?.toUpperCase())) {
      postSessionStart(product, custId, source);
    }
  }, []);
  return null;
};

export default AppRouter;
