export default class EventTracker {
  constructor(props) {
    const { GA_CLIENT_ID } = props;
    this.GA_CLIENT_ID = GA_CLIENT_ID;
    if (
      GA_CLIENT_ID === null ||
      GA_CLIENT_ID === undefined ||
      GA_CLIENT_ID === ""
    ) {
      // throw new Error(`GA_CLIENT_ID is invalid`);
      console.log(`GA_CLIENT_ID is invalid`);

      return false;
    } else {
      // var script = document.createElement("script");
      // script.setAttribute(
      //   "src",
      //   `https://www.googletagmanager.com/gtag/js?id=${GA_CLIENT_ID}`
      // );
      // script.setAttribute("type", "text/javascript");
      // document.getElementsByTagName("head")[0].appendChild(script);
      // window.GEM = window.GEM || [];
      // window.gtag = function () {
      //   GEM.push(arguments);
      // };
      // window.gtag("js", new Date());
      // window.gtag("config", `${GA_CLIENT_ID}`);
    }
    // (function (h, o, t, j, a, r) {
    //   h.hj =
    //     h.hj ||
    //     function () {
    //       (h.hj.q = h.hj.q || []).push(arguments);
    //     };
    //   h._hjSettings = { hjid: 2092174, hjsv: 6 };
    //   a = o.getElementsByTagName('head')[0];
    //   r = o.createElement('script');
    //   r.async = 1;
    //   r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    //   a.appendChild(r);
    // })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
  trackEvent = (data) => {
    if (
      this.GA_CLIENT_ID === null ||
      this.GA_CLIENT_ID === undefined ||
      this.GA_CLIENT_ID === ""
    ) {
      console.warn(
        "GA EVENT LOGGIN IS DISABLED AS THE GA CLIENT ID IS INVALID"
      );
      return false;
    }

    setTimeout(() => {
      if (window.location.host.indexOf("localhost") >= 0) {
        console.log({
          hitType: "event",
          eventCategory: `${process.env.REACT_APP_ENV.toUpperCase()} -- ${
            data.eventCategory
          }`,
          eventAction: data.eventAction || null,
          eventValue: data.payload ? JSON.stringify(data.payload || {}) : null
        });
      } else {
        if ("ga" in window && process.env.REACT_APP_ENV === "PRODUCTION") {
          let tracker = ga.getAll()[0];
          if (tracker)
            tracker.send("event", {
              hitType: "event",
              eventCategory: data.eventCategory,
              eventAction: data.eventAction || null
            });
        }
      }
    }, 1000);
  };
}
