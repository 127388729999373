export const FLUSH_STORAGE = "FLUSH_STORAGE";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const SET_JOURNEY_PROPS = "SET_JOURNEY_PROPS";
export const SET_OPEN_JOURNEY_PROPS = "SET_OPEN_JOURNEY_PROPS";

export const UPDATE_JOURNEY_DATA = "UPDATE_JOURNEY_DATA";
export const UPDATE_STORE = "UPDATE_STORE";

export const GET_JOURNEY_BEGIN = "GET_JOURNEY_BEGIN";
export const GET_JOURNEY_SUCCESS = "GET_JOURNEY_SUCCESS";
export const GET_JOURNEY_FAILURE = "GET_JOURNEY_FAILURE";

export const GET_OFFER_BEGIN = "GET_OFFER_BEGIN";
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS";
export const GET_OFFER_FAILURE = "GET_OFFER_FAILURE";

export const GET_CONFIGS_BEGIN = "GET_CONFIGS_BEGIN";
export const GET_CONFIGS_SUCCESS = "GET_CONFIGS_SUCCESS";
export const GET_CONFIGS_FAILURE = "GET_CONFIGS_FAILURE";

export const DROP_USER_BEGIN = "DROP_USER_BEGIN";
export const DROP_USER_SUCCESS = "DROP_USER_SUCCESS";
export const DROP_USER_FAILURE = "DROP_USER_FAILURE";

export const SAVE_JOURNEY_BEGIN = "SAVE_JOURNEY_BEGIN";
export const SAVE_JOURNEY_SUCCESS = "SAVE_JOURNEY_SUCCESS";
export const SAVE_JOURNEY_FAILURE = "SAVE_JOURNEY_FAILURE";

export const VALIDATE_AGCODE_BEGIN = "VALIDATE_AGCODE_BEGIN";
export const VALIDATE_AGCODE_SUCCESS = "VALIDATE_AGCODE_SUCCESS";
export const VALIDATE_AGCODE_FAILURE = "VALIDATE_AGCODE_FAILURE";

export const SUBMIT_APPLICATION_BEGIN = "SUBMIT_APPLICATION_BEGIN";
export const SUBMIT_APPLICATION_SUCCESS = "SUBMIT_APPLICATION_SUCCESS";
export const SUBMIT_APPLICATION_FAILURE = "SUBMIT_APPLICATION_FAILURE";

export const UPLOAD_DOCUMENT_BEGIN = "UPLOAD_DOCUMENT_BEGIN";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAILURE = "UPLOAD_DOCUMENT_FAILURE";

export const OPMK_ELIGIBILE_BEGIN = "OPMK_ELIGIBILE_BEGIN";
export const OPMK_ELIGIBILE_SUCCESS = "OPMK_ELIGIBILE_SUCCESS";
export const OPMK_ELIGIBILE_FAILURE = "OPMK_ELIGIBILE_FAILURE";

export const SUIT_MATRX_OFFER_BEGIN = "SUIT_MATRX_OFFER_BEGIN";
export const SUIT_MATRX_OFFER_SUCCESS = "SUIT_MATRX_OFFER_SUCCESS";
export const SUIT_MATRX_OFFER_FAILURE = "SUIT_MATRX_OFFER_FAILURE";

export const START_OPEN_JOURNEY_BEGIN = "START_OPEN_JOURNEY_BEGIN";
export const START_OPEN_JOURNEY_SUCCESS = "START_OPEN_JOURNEY_SUCCESS";
export const START_OPEN_JOURNEY_FAILURE = "START_OPEN_JOURNEY_FAILURE";

export const VALIDATE_PINCODE_BEGIN = "VALIDATE_PINCODE_BEGIN";
export const VALIDATE_PINCODE_SUCCESS = "VALIDATE_PINCODE_SUCCESS";
export const VALIDATE_PINCODE_FAILURE = "VALIDATE_PINCODE_FAILURE";

export const GET_ILLUSTRATION_BEGIN = "GET_ILLUSTRATION_BEGIN";
export const GET_ILLUSTRATION_SUCCESS = "GET_ILLUSTRATION_SUCCESS";
export const GET_ILLUSTRATION_FAILURE = "GET_ILLUSTRATION_FAILURE";
