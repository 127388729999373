import { useSelector } from "react-redux";
import InstainsureLogo from "../../assets/images/AD/10/21/26075_21.svg";
import BelairLoader from "../../assets/images/AD/10/21/26075_37.svg";
import { BELAIR_PARTNER } from "../../configs/partner";
export default function Loader({ ...props }) {
  const {
    pasaStore: { source }
  } = useSelector((state) => state);

  return (
    <div className="loader">
      <section className="loader_wrapper">
        <img
          src={BELAIR_PARTNER.includes(source) ? BelairLoader : InstainsureLogo}
          className="_logo"
          alt="InstaInsure"
        />
        <p>Please Wait...</p>
      </section>
    </div>
  );
}
