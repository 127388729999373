import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise";
import rootReducer from "../reducers";
import { Decrypt } from "../modules";
let middleware = "";
// if (process.env.NODE_ENV === 'PRODUCTION' || process.env.NODE_ENV === 'test') {
if (process.env.NODE_ENV === "PRODUCTION") {
  middleware = applyMiddleware(promiseMiddleware, thunk);
} else {
  if (process.env.REACT_APP_ENABLE_REDUX_LOGS == "true") {
    middleware = applyMiddleware(promiseMiddleware, thunk, createLogger());
    // middleware = applyMiddleware(promiseMiddleware, thunk);
  } else {
    middleware = applyMiddleware(promiseMiddleware, thunk);
  }
}
let persistantStore = {};

if (process.env.REACT_APP_PERSITENT_STORAGE == "true") {
  persistantStore = sessionStorage._store
    ? Decrypt(sessionStorage._store, "local")
    : {};
}

export default createStore(rootReducer, persistantStore, middleware);
