import axios from "axios";
import { Encrypt, Decrypt } from "../modules";

axios.interceptors.request.use(
  (config) => {
    config.headers["content-type"] = `application/json`;
    if (config.url.indexOf("document") < 0) {
      if (
        config.method === "post" &&
        config.data &&
        process.env.REACT_APP_ENABLE_ENCRYPTION === "true"
      ) {
        config.data = { payload: Encrypt(config.data) };
      } else {
      }
    } else if (config.url.indexOf("document") >= 0) {
      config.headers["content-type"] = `multipart/form-data`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (config) => {
    if (config.data.payload) {
      try {
        config.data = Decrypt(config.data);
      } catch (err) {}
    }
    return config;
  },
  (error) => {
    if (error?.response?.data) {
      if (error.response.data.payload) {
        error.response.data = Decrypt(error.response.data);
      }
      if (
        Number(error?.response?.status >= 500) &&
        error?.response?.config?.url.includes("/offer")
      ) {
        window.location.href = "/service-down";
      }
    }
    return Promise.reject(error);
  }
);
